import { useState } from "react";
import HamburguerMenu from "./HamburguerMenu";
import NavBar from "./NavBar";
import logocerco from "../assets/logo-header-principal.png";
import whatsapp from "../assets/whatsapp-white.png";
import logoresponsive from "../assets/logo-principal-responsive.png";

const Header = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="header-container">
        <div className="top-header">
          <div className="top-header-details wrapper">
            <p>info@cercoelectricoperimetral.com.ar</p>
            <a
              href="https://api.whatsapp.com/send?phone=541133509246"
              target="_blank"
            >
              <img className="wp-top-header" src={whatsapp} alt="" />
              011 - 3350 - 9246
            </a>
          </div>
        </div>
        <div className="bottom-header ">
          <div className="bottom-header-details wrapper">
            <HamburguerMenu
              classname={`hamburguer-menu ${open ? "open" : ""}`}
              handleClick={handleClick}
            />
            <img
              className="logo-principal"
              src={logocerco}
              alt="Logo principal de Cerco Perimetral"
            />
            <img
              className="logo-principal-responsive"
              src={logoresponsive}
              alt="Logo principal de Cerco Perimetral"
            />
          </div>
        </div>
      </div>
      <div>{open && <NavBar handleClick={handleClick} />}</div>
    </>
  );
};

export default Header;
