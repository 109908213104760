import logo from "../assets/logo-principal-responsive.png";
import { Link } from "react-router-dom";
import facebook from "../assets/facebook.png";
import instagram from "../assets/instagram.png";
import youtube from "../assets/youtube.png";
import hgstudio from "../assets/logo-hg.png";
import Iframe from "react-iframe";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-details wrapper">
        <img className="logo-footer" src={logo} alt="" />
        <div className="terms-container">
          <div>
            <Link className="terms" to="/terms">
              Términos y condiciones
            </Link>
          </div>
          <div>
            <Link className="terms" to="/questions">
              Preguntas Frecuentes
            </Link>
          </div>
        </div>
        <div className="info-footer-cont">
          <p>Pola 1100, Caba.</p>
          <p>Lunes a sábados de 8 a 18hs.</p>
        </div>
        <div className="redes-container">
          <a href="https://www.facebook.com/cercoelectricoya" target="_blank">
            <img src={facebook} alt="logo facebook" />
          </a>

          <a
            href="https://www.youtube.com/channel/UC91XrZjraWPUDUs7OL9cb9g"
            target="_blank"
          >
            <img src={youtube} alt="logo youtube" />
          </a>
          <a
            href="https://www.instagram.com/cercoelectricoperimetral/"
            target="_blank"
          >
            <img src={instagram} alt="logo instagram" />
          </a>
        </div>
        <a className="logo-hg" href="https://hgstudio.com.ar/" target="_blank">
          <img src={hgstudio} alt="Logo de hg studio" />
          diseño
        </a>
      </div>
      <div className="contact-map">
        <Iframe
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3282.229837041446!2d-58.5037386845025!3d-34.648898067397454!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc9a972673857%3A0x7662a3d00d41f273!2sPola%201100%2C%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1669601535222!5m2!1ses-419!2sar"
          width="500px"
          height="300px"
          id=""
          display="block"
          position="relative"
          frameborder="0"
          margin="50px"
          border-radius="30px"
        />
      </div>
    </div>
  );
};

export default Footer;
